import { useEffect, useState } from 'react'
import { rtdb, auth } from './firebase'
import { onDisconnect } from 'firebase/database'

import {
  signInAnonymously,
  setPersistence,
  browserLocalPersistence
} from 'firebase/auth'

import {
  query,
  ref,
  set,
  get,
  onValue,
  off,
} from 'firebase/database'

import useZoom from './useZoom'

function generateCode(num: number = 6) {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  let code = ''
  for (let i = 0; i < num; i++) {
    code += charset[Math.floor(charset.length * Math.random())]
  }
  return code
}

export default function useFirebase() {

  const [uid, setUid] = useState<String>()
  const [data, setData] = useState<any>()
  const [error, setError] = useState<String>('')
  const [code, setCode] = useState<String>('')
  const [zoom] = useZoom()

  useEffect(() => {
    if (zoom.zoomCtx && zoom.zoomCtx.deviceId) {
      console.log('setUid', zoom.zoomCtx.deviceId)
      const id = zoom.zoomCtx.deviceId.split('/').join('')
      setUid(`zoom/${id}`)
    }
  }, [zoom])

  useEffect(() => {
    if (!uid && zoom.issue) {
      console.log('zoom issue', zoom.issue)
      console.log('authenticate')
      const authenticateAnonymously = async () => {
        try {
          await setPersistence(auth, browserLocalPersistence)
          const user = await signInAnonymously(auth)
          if (user && user.user && user.user.uid) {
            console.log('setUid', user.user.uid)
            setUid(user.user.uid)
          }
        } catch (error: any) {
          setError(error ? error.message : error)
        }
      };
  
      if (!auth.currentUser) {
        authenticateAnonymously()
      } else {
        console.log('setUid', auth.currentUser.uid)
        setUid(auth.currentUser.uid)
      }
    } else if (uid) {

      const setupCode = async (uid: String): Promise<void> => {
        const code = generateCode()
        const codeRef = ref(rtdb, `mobile/codes/${code}`)
        return get(query(codeRef)).then((snapshot) => {
          const data = snapshot.val()
          if (data === null) {
            setCode(code)
            set(codeRef, uid)
            onDisconnect(codeRef).set(null)
          } else {
            return setupCode(uid)
          }
        })
      }

      const subscribe = async () => {
        console.log('subscribe');
        try {
          const uidRef = ref(rtdb, `mobile/pool/${uid}`)
          onValue(query(uidRef), (snapshot) => {
            console.log('receive data')
            const data = snapshot.val()
            console.log('data', data);
            if (data === null) {
              set(uidRef, 'online')
            } else if (data !== 'online') {
              const onlineRef = ref(rtdb, `mobile/connect/${uid}`)
              set(onlineRef, {status:'online', ua: navigator.userAgent})
              onDisconnect(onlineRef).update({status: 'offline', ua: navigator.userAgent})
              setData(data)
            } else {
              setupCode(uid)
              setData(null)
            }
          });
        } catch (error: any) {
          // setError(error ? error.message : error)
          console.log('error: receive data')
          setData(null)
        }
      }

      const connectZoom = async () => {
        try {
          await setPersistence(auth, browserLocalPersistence)
          await signInAnonymously(auth)
          console.log('authenticated')
          await subscribe()
        } catch (error: any) {
         // setError(error ? error.message : error)
         console.log('receive data')
         setData(null)
        }
      };

      if (zoom && zoom.zoomCtx) {
        connectZoom()
      } else {
        console.log('authenticated')
        subscribe()
      }
    }
  }, [uid, zoom])

  useEffect(() => {
    return () => {
      if (uid) {
        console.log('unsubscribe')
        off(query(ref(rtdb, `mobile/pool/${uid}`)))
      }
    }
  }, [uid])

  return [ uid, code, data, error ]
}
